import React, { useEffect, useState, useContext } from 'react';
import Layout from "../components/Layout";
import { LangStateContext } from "../context/LangContext";
import moment from 'moment';
import '../styles/SchedulePage.scss';
import 'moment/locale/id';
import Input from '../components/input/Input';
import qs from 'qs';
import Button from '../components/button/Button';
import { backendInstance } from '../api/instance';
moment.locale('id');


const SchoolCompetitionSchedulePage = (props) => {
  const { isIndoSelected } = useContext(LangStateContext);
  console.log(isIndoSelected);

  const [week, setWeek] = useState(moment().isoWeek());
  const [dates, setDates] = useState([]);
  const [disablePrev, setDisablePrev] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const [error, setError] = useState();
  const [data, setData] = useState(
    qs.parse(props.location.search, {
      ignoreQueryPrefix: true,
    })
  );

  useEffect(() => {
    const loopDates = getISOWeekDates(week);
    setDates(loopDates);
  }, [week]);

  const getISOWeekDates = (isoWeekNum = 1, year = new Date().getFullYear()) => {
    let d = moment()
      .isoWeek(1)
      .startOf('isoWeek')
      .add(isoWeekNum - 1, 'weeks');
    for (var dates = [], i = 0; i < 7; i++) {
      let disable = false;
      if (d.day() === 0 || d.day() === 6) {
        disable = true;
      }
      if (d.diff(moment(), 'day') < 0) {
        disable = true;
      }
      dates.push({
        date: d.format('YYYY-MM-DD'),
        day: d.format('dddd'),
        month: d.format('MMM'),
        dateFormat: d.format('DD'),
        disable,
      });
      d.add(1, 'day');
    }
    return dates;
  };

  const changeWeek = (type) => {
    let newWeek = week;
    if (type === 'next') {
      newWeek = week + 1;
      setWeek(newWeek);
    }
    if (type === 'prev') {
      newWeek = week - 1;
      setWeek(newWeek);
    }
    if (newWeek === moment().isoWeek()) {
      setDisablePrev(true);
    } else {
      setDisablePrev(false);
    }
  };

  const selectDate = (selectedDate) => {
    if (!selectedDate.disable) {
      setDate(selectedDate.date);
    }
  };

  const selectTime = (selectedTime) => {
    setTime(selectedTime);
  };

  const changeForm = (key, value) => {
    setData((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const submitSchedule = async () => {
    setError();
    setLoading(true);
    try {
      if (!date) {
        setError('Silahkan Pilih Tanggal Webinar');
      }
      if (!time) {
        setError('Silahkan Pilih Waktu Webinar');
      }

      if (date && time) {
        const request = {
          ...data,
          date,
          time,
        };
        await backendInstance.post('/school_competition/submit_schedule', request);
        setSubmitted(true);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          setError(error.response.data.message);
        } else {
          setError('Terjadi Kesalahan. Mohon Hubungi Tim Kami');
        }
      } else {
        setError('Terjadi Kesalahan. Mohon Hubungi Tim Kami');
      }
    }
    setLoading(false);
  };

  return (
    <Layout>
      <div id='page' className='privacy-page'>
        <div className='privacy-page-text'>
          <div id='primary' className='content-area'>
            <main id='main' className='site-main'>
              <article id='post-410' className='post-410 page type-page status-publish hentry'>
                <header className='entry-header'>
                  <h1 className='entry-title'>Webinar Kompetisi Sekolah</h1>
                </header>
                {error && <div className='schedule-alert'>{error}</div>}
                {submitted ? (
                  <h3>
                    Terima Kasih Sudah Memilih Tanggal dan Waktu untuk Webinar Kompetisi Sekolah. Kami sudah mengirimkan
                    konfirmasi melalui Email dan Whatsapp Anda.
                  </h3>
                ) : (
                  <div className='entry-content schedule-content'>
                    <h3 className='schedule-subtitle'>Pilih Tanggal</h3>

                    <div className='schedule-button'>
                      <Button type='blue' disabled={disablePrev} onClick={() => changeWeek('prev')}>
                        Minggu Sebelumnya
                      </Button>
                      <Button type='blue' onClick={() => changeWeek('next')}>
                        Minggu Depan
                      </Button>
                    </div>

                    <div className='schedule-calendar'>
                      {dates.map((d) => (
                        <div
                          onClick={() => selectDate(d)}
                          className={`schedule-date ${!d.disable ? 'schedule-date-enable' : ''} ${
                            d.date === date ? 'selected' : ''
                          }`}
                        >
                          <p className='schedule-date-day'>{d.day}</p>
                          <p className='schedule-date-date'>
                            <strong>{d.dateFormat}</strong>
                          </p>
                          <p className='schedule-date-month'>{d.month}</p>
                        </div>
                      ))}
                    </div>
                    <div className='schedule-times'>
                      <h3 className='schedule-subtitle'>Pilih Waktu</h3>

                      <div
                        onClick={() => selectTime('09:00')}
                        className={`schedule-time ${time === '09:00' ? 'selected' : ''}`}
                      >
                        09:00 - 10:00 WIB
                      </div>
                      <div
                        onClick={() => selectTime('13:00')}
                        className={`schedule-time ${time === '13:00' ? 'selected' : ''}`}
                      >
                        13:00 - 14:00 WIB
                      </div>
                      <div
                        onClick={() => selectTime('17:00')}
                        className={`schedule-time ${time === '17:00' ? 'selected' : ''}`}
                      >
                        17:00 - 18:00 WIB
                      </div>
                      <div
                        onClick={() => selectTime('19:00')}
                        className={`schedule-time ${time === '19:00' ? 'selected' : ''}`}
                      >
                        19:00 - 20:00 WIB
                      </div>
                    </div>
                    <div className='schedule-form'>
                      <h3>Masukkan informasi Anda</h3>
                      <div className='schedule-input'>
                        <Input
                          placeholder='Nama'
                          inputName='name'
                          value={data.name}
                          label='Nama'
                          onChange={(v) => changeForm('name', v.target.value)}
                        />
                      </div>
                      <div className='schedule-input'>
                        <Input
                          placeholder='Email'
                          inputName='email'
                          value={data.email}
                          label='Email'
                          onChange={(v) => changeForm('email', v.target.value)}
                        />
                      </div>
                      <div className='schedule-input'>
                        <Input
                          placeholder='Telpon'
                          inputName='phone'
                          value={data.phone}
                          label='Telpon'
                          onChange={(v) => changeForm('phone', v.target.value)}
                        />
                      </div>
                      <div className='schedule-input'>
                        <Input
                          placeholder='Sekolah'
                          inputName='school'
                          value={data.school}
                          label='Sekolah'
                          onChange={(v) => changeForm('school', v.target.value)}
                        />
                      </div>

                      <div className='schedule-submit'>
                        <Button type='blue' onClick={submitSchedule} block disabled={loading}>
                          {loading ? 'Loading...' : 'Submit'}
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </article>
            </main>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SchoolCompetitionSchedulePage;