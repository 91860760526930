import React from 'react';
import PropTypes from 'prop-types';
import './Button.scss';

const Button = ({ type, children, block, style, onClick, disabled = false }) => {
  if (type === 'white' || undefined || null || '')
    return (
      <button
        className={`button white ${block ? 'block' : ''} ${disabled ? 'disabled' : ''}`}
        style={style}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
    );

  if (type === 'blue')
    return (
      <button
        className={`button blue ${block ? 'block' : ''} ${disabled ? 'disabled' : ''}`}
        style={style}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
    );
};

Button.propTypes = {
  type: PropTypes.string,
  block: PropTypes.bool,
  style: PropTypes.object,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Button;
