import React from 'react';
import PropTypes from 'prop-types';
import './Input.scss';

const Input = ({ placeholder, value, onChange, label, inputName }) => {
  return (
    <React.Fragment>
    <label className='input-label' >{label}</label>
    <input 
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className='input'
      name={inputName}
    />
    </React.Fragment>
  );
};

Input.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  inputName: PropTypes.string,
};

export default Input;